import { TDate, register, format as ts } from 'timeago.js'

// adapted from https://github.com/hustcc/timeago.js/blob/master/src/lang/en_short.ts
function localeShortEnglish(_number: number, index: number): [string, string] {
  return [
    ['now', 'right now'],
    ['%ss', 'in %ss'],
    ['1m', 'in 1m'],
    ['%sm', 'in %sm'],
    ['1h', 'in 1h'],
    ['%sh', 'in %sh'],
    ['1d', 'in 1d'],
    ['%sd', 'in %sd'],
    ['1w', 'in 1w'],
    ['%sw', 'in %sw'],
    ['1mo', 'in 1mo'],
    ['%smo', 'in %smo'],
    ['1yr', 'in 1yr'],
    ['%syr', 'in %syr'],
  ][index] as [string, string]
}

register('en_truncated', localeShortEnglish)

export function formatTimestamp(date: TDate, locale?: string) {
  return ts(date, locale, { relativeDate: new Date() })
}

/**
 * formatCountdownShort formats a countdown to a human readable string of the form
 * over 1 year: 1y
 * over 4 weeks: 1mo
 * over 7 days: 1w
 * over 24 hours: 1d
 * over 1 hour: 1h
 * below 1 hour: 00:00
 *
 * @param diff the difference in `.getTime()` between the current time and the time to format
 */
export const formatCountdownShort = (diff: number): string => {
  if (diff <= 0) {
    return '00:00'
  }

  if (diff > 31536000000) {
    return `${Math.floor(diff / 31536000000)}y`
  }

  if (diff > 2592000000) {
    return `${Math.floor(diff / 2592000000)}mo`
  }

  if (diff > 604800000) {
    return `${Math.floor(diff / 604800000)}w`
  }

  if (diff > 86400000) {
    return `${Math.floor(diff / 86400000)}d`
  }

  if (diff > 3600000) {
    return `${Math.floor(diff / 3600000)}h`
  }

  const minutes = Math.floor((diff % 3600000) / 60000)
  const seconds = Math.floor(((diff % 3600000) % 60000) / 1000)

  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`
}

export const isValidIsoTimestamp = (timestamp: string | undefined): boolean =>
  timestamp !== undefined && timestamp !== '0001-01-01T00:00:00Z'

export const formatDate = (dateString: string) => {
  const date = new Date(dateString)
  const currentDate = new Date()

  const diffMinutes = Math.max(
    0,
    Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60),
  )

  if (diffMinutes < 60) {
    return `${diffMinutes}m ago`
  }

  const diffHours = Math.floor(diffMinutes / 60)
  if (diffHours < 24) {
    return `${diffHours}h ago`
  }

  const diffDays = Math.floor(diffHours / 24)
  if (diffDays < 7) {
    return `${diffDays}d ago`
  }

  const diffWeeks = Math.floor(diffDays / 7)
  if (diffWeeks < 4) {
    return `${diffWeeks}w ago`
  }

  const diffMonths = Math.floor(diffDays / 30)

  return `${diffMonths}mo ago`
}

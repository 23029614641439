import React, { useCallback } from 'react'
import { Button } from 'components/Button'
import { Modal, ModalClose } from 'components/Modal'
import { CollectionFlag } from 'utils/api'
import classNames from 'utils/classnames'
import { textSecondary } from 'utils/theme'
import { copyForFlag, warningTitle } from './messages'

type Props = {
  flags: CollectionFlag[]
  open: boolean
  onClose(shouldMint: boolean): void
}

function ProjectWarningLabelModal({ flags, open, onClose }: Props) {
  const handleClose = useCallback(() => onClose(false), [onClose])
  const handleContinue = useCallback(() => onClose(true), [onClose])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={warningTitle}
      content={
        <div className="flex flex-col w-full gap-12">
          <ul className="list-disc list-inside text-sm text-left">
            {flags.map((flag) => (
              <li key={flag}>{copyForFlag[flag]}</li>
            ))}
          </ul>
          <div className="flex flex-col w-full gap-6">
            <ModalClose asChild>
              <Button className="w-full" label="Take me back" />
            </ModalClose>
            <button
              type="button"
              className={classNames(textSecondary, 'text-xs font-semibold p-2')}
              onClick={handleContinue}
            >
              Continue
            </button>
          </div>
        </div>
      }
    />
  )
}

export default React.memo(ProjectWarningLabelModal)

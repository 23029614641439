import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import {
  PrepareSendTransactionResult,
  SendTransactionResult,
} from '@wagmi/core'
import { useReferrer } from 'hooks/useReferrer'
import {
  usePrepareSendTransaction,
  usePublicClient,
  useSendTransaction,
  useSwitchNetwork,
  useWaitForTransaction,
} from 'wagmi'
import { waitForTransaction } from 'wagmi/actions'
import { useOpenBridgingModal } from 'components/BridgingModal'
import { Button } from 'components/Button'
import MintModal, { MintEvent } from 'components/MintModal'
import { ProjectWarningLabelModal } from 'components/ProjectWarningLabel'
import { handleTransactionFailureError, useUpdatePendingTxCache } from './utils'
import {
  CollectionFlag,
  Contract,
  ContractTransaction,
  MintButtonSettings,
  MintSource,
  fundropPointsEndpoint,
  getChainId,
  submitTx,
} from 'utils/api'
import { getChainById, isSupportedBridgableChain } from 'utils/chains'
import { useAccountOrLocalCheckpoint } from 'utils/checkpoints'
import classnames from 'utils/classnames'
import { mutateRead } from 'utils/swr'
import {
  createEmailSignupToast,
  createInsufficientFundsToast,
  createNetworkMismatchToast,
  createNetworkNotSupportedToast,
  createTransactionPendingToast,
  createTransactionToast,
  dismissToast,
} from 'utils/toasts'
import { useAccount } from 'utils/useAccount'
import {
  formatTokenAmount,
  isInsufficientFundsGasEstimation,
  isMintButtonDisabled,
} from 'utils/web3/helpers'
import {
  parseTokenIds,
  prepareTransaction,
} from 'utils/web3/transactionHelpers'
import { useOpenConnectModal } from 'utils/web3/walletconnect'

export function TransactionButtonUnstyled({
  label,
  ariaLabel,
  onClick,
  disabled,
  pending,
  buttonSettings = {},
  className,
}: {
  label: NonNullable<ReactNode>
  ariaLabel?: string
  onClick?: () => void
  disabled?: boolean
  pending?: boolean
  buttonSettings: MintButtonSettings | undefined
  className?: string
}) {
  const { backgroundColor, textColor } = buttonSettings

  return (
    <Button
      variant="heavyModal"
      className={classnames('w-full md:w-96', className)}
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
      }}
      labelClassName="truncate"
      onClick={onClick}
      disabled={disabled}
      pending={pending}
      label={label}
      ariaLabel={ariaLabel}
    />
  )
}

type Props = {
  transaction: ContractTransaction
  preparedTransaction: UseQueryResult<PrepareSendTransactionResult, Error>
  collectionContract: Contract
  collectionName: string | undefined
  collectionFlags: CollectionFlag[] | undefined
  isFunContract?: boolean
  labelOverride?: string
  ignoreWarnings?: boolean
  buttonSettings: MintButtonSettings | undefined
  source: MintSource
  wrapperClassName?: string
  buttonClassName?: string
  onMintSuccess?: () => void
  gasIsRefunded?: boolean
  pendingUntilTxIsConfirmed?: boolean
}

function TransactionButton({
  transaction,
  preparedTransaction,
  collectionContract,
  collectionName,
  collectionFlags,
  isFunContract = false,
  labelOverride,
  ignoreWarnings = false,
  buttonSettings = {},
  source,
  wrapperClassName,
  buttonClassName,
  onMintSuccess,
  gasIsRefunded = false,
  pendingUntilTxIsConfirmed = false,
}: Props) {
  const { address, chainId, account } = useAccount()
  const publicClient = usePublicClient({ chainId })

  const updatePendingTxCache = useUpdatePendingTxCache(collectionContract)
  const { openConnectModal } = useOpenConnectModal()

  const {
    data: config,
    error: prepareError,
    refetch: refetchPrepare,
  } = preparedTransaction

  const {
    isLoading: txIsLoading,
    sendTransactionAsync,
    data: txData,
  } = useSendTransaction(
    config ?? {
      mode: 'prepared',
    },
  )

  const { isLoading: waitForTxIsLoading } = useWaitForTransaction({
    hash: txData?.hash,
    enabled: pendingUntilTxIsConfirmed,
  })

  const buttonIsPending = pendingUntilTxIsConfirmed
    ? txIsLoading || waitForTxIsLoading
    : txIsLoading

  const isWrongChain = useMemo(
    () => chainId !== getChainId(collectionContract),
    [chainId, collectionContract],
  )

  const buttonDisabled = useMemo(() => {
    return isMintButtonDisabled(
      isWrongChain,
      transaction,
      address,
      prepareError,
    )
  }, [isWrongChain, transaction, address, prepareError])

  const { switchNetwork } = useSwitchNetwork()
  const [checkpointReached, handleCheckpointReached] =
    useAccountOrLocalCheckpoint('email-toast', false)

  const [mintEvent, mintEventSet] = useState<MintEvent>()

  const attemptMint = useCallback(async () => {
    if (address === undefined) {
      // shouldn't get here since we gate it below
      openConnectModal()
      return
    }

    if (sendTransactionAsync === undefined || address === undefined) {
      // shouldn't get here since we gate it on the button
      return
    }

    if (publicClient !== undefined) {
      // don't block on waiting for gas price
      ;(async () => {
        // TODO: this isn't cancelled if the work outside this function fails
        // quickly and could result in an error and this pending tx modal being shown
        const gasPrice = await publicClient.getGasPrice()

        mintEventSet({
          minterAddress: address,
          collectionName: collectionName ?? 'Unknown Collection',
          collectionContract,
          isFunContract,
          ethValue: transaction.ethValue,
          estGasFee: gasPrice * (config?.gas ?? 1n),
          points: transaction.points,
          gasIsRefunded,
        })
      })()
    }

    let txResponse: SendTransactionResult | undefined = undefined
    try {
      txResponse = await sendTransactionAsync()
    } catch (e) {
      // failure toast handled in this function
      handleTransactionFailureError(e)
      return
    } finally {
      mintEventSet(undefined)
    }

    // send this off without waiting / worrying about an error
    submitTx(
      address,
      txResponse.hash,
      getChainId(collectionContract),
      transaction.isAllowlist,
      source,
    )

    const pendingToastId = await createTransactionPendingToast(
      getChainId(collectionContract),
      txResponse.hash,
    )

    updatePendingTxCache()

    try {
      const tx = await waitForTransaction({
        hash: txResponse.hash,
      })

      createTransactionToast({
        tokenIds: parseTokenIds(tx),
        transactionHash: tx.transactionHash,
        collectionContract,
        collectionName,
        isFunContract,
        minterAddress: address,
        toastId: pendingToastId,
      })

      const shouldHideForAccount = account?.emailVerified ?? true
      if (!checkpointReached && !shouldHideForAccount) {
        createEmailSignupToast(handleCheckpointReached)
      }

      onMintSuccess?.()

      setTimeout(async () => {
        try {
          // this will error if the user hasn't minted their fundrop pass
          await mutateRead(fundropPointsEndpoint({ address: address ?? '' }))
        } catch {
          // ignored
        }
      }, 2000) // give indexer some time before requesting points
    } catch (e) {
      // cancel the pending toast since we have an error
      dismissToast(pendingToastId)

      // failure toast handled in this function
      handleTransactionFailureError(e)
    }

    // estimate again once it has confirmed since some contracts let you only mint once
    refetchPrepare()
  }, [
    account?.emailVerified,
    address,
    checkpointReached,
    collectionContract,
    collectionName,
    isFunContract,
    config?.gas,
    gasIsRefunded,
    handleCheckpointReached,
    onMintSuccess,
    openConnectModal,
    publicClient,
    refetchPrepare,
    sendTransactionAsync,
    source,
    transaction,
    updatePendingTxCache,
  ])

  const [warningModalIsOpen, warningModalIsOpenSet] = useState(false)

  const handleWarningModalClose = useCallback(
    async (shouldMint: boolean) => {
      warningModalIsOpenSet(false)

      if (shouldMint) {
        await attemptMint()
      }
    },
    [attemptMint],
  )

  const openBridgeModal = useOpenBridgingModal()

  const handleMint = useCallback(async () => {
    if (address === undefined) {
      openConnectModal()
      return
    }

    const targetChainId = getChainId(collectionContract)
    if (chainId !== targetChainId) {
      const targetChain = getChainById(targetChainId)

      // In practice target chain should never be undefined unless
      // our API was surfacing a contract with a chain that isn't
      // included in our supportedChains list
      if (targetChain === undefined) {
        createNetworkNotSupportedToast(targetChainId)
        return
      }

      createNetworkMismatchToast(targetChain.name)
      switchNetwork?.(targetChainId)
      return
    }

    if (isInsufficientFundsGasEstimation(prepareError)) {
      const targetChain = getChainById(targetChainId)
      if (
        targetChain !== undefined &&
        isSupportedBridgableChain(targetChain.id)
      ) {
        openBridgeModal(targetChain)
      }
      createInsufficientFundsToast()
      return
    }

    if (sendTransactionAsync === undefined) {
      return
    }

    if (
      collectionFlags !== undefined &&
      collectionFlags.length > 0 &&
      !ignoreWarnings
    ) {
      warningModalIsOpenSet(true)
    } else {
      await attemptMint()
    }
  }, [
    address,
    attemptMint,
    chainId,
    collectionContract,
    collectionFlags,
    ignoreWarnings,
    openBridgeModal,
    openConnectModal,
    prepareError,
    sendTransactionAsync,
    switchNetwork,
  ])

  const handleMintModalClose = useCallback(() => {
    mintEventSet(undefined)
  }, [])

  const labelText = labelOverride ?? (
    <>
      Mint {transaction.nftCount} for{' '}
      <span className="uppercase">
        {formatTokenAmount({
          amount: transaction.ethValue,
        })}
      </span>
    </>
  )

  return (
    <>
      {collectionFlags !== undefined && !ignoreWarnings && (
        <ProjectWarningLabelModal
          open={warningModalIsOpen}
          flags={collectionFlags}
          onClose={handleWarningModalClose}
        />
      )}
      <div
        className={classnames(
          'group relative w-full md:w-auto',
          wrapperClassName,
        )}
      >
        <TransactionButtonUnstyled
          className={buttonClassName}
          onClick={handleMint}
          disabled={buttonDisabled}
          pending={buttonIsPending}
          buttonSettings={buttonSettings}
          label={labelText}
        />
      </div>
      <MintModal
        mintEvent={mintEvent}
        open={mintEvent !== undefined}
        onClose={handleMintModalClose}
      />
    </>
  )
}

export default React.memo(TransactionButton)

type PreparedTransactionButtonProps = Omit<Props, 'preparedTransaction'>

export function PreparedTransactionButton(
  props: PreparedTransactionButtonProps,
) {
  const { address } = useAccount()
  const referrer = useReferrer()

  const tx = useMemo(
    () =>
      address !== undefined
        ? prepareTransaction(address, props.transaction, referrer)
        : undefined,
    [address, props.transaction, referrer],
  )
  const config = usePrepareSendTransaction({
    ...tx,
    chainId: getChainId(props.collectionContract),
    enabled: address !== undefined,
    cacheTime: 0,
    staleTime: 0,
  })

  return (
    <TransactionButton
      preparedTransaction={
        config as unknown as UseQueryResult<PrepareSendTransactionResult, Error>
      }
      {...props}
    />
  )
}

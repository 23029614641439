import {
  Hex,
  TransactionReceipt,
  TransactionRequest,
  decodeEventLog,
  isAddressEqual,
  parseAbi,
  zeroAddress,
} from 'viem'
import { AddressHash, ContractTransaction } from 'utils/api'
import { createReferrerReplacement } from 'utils/treasury'

const fallbackAddress = '0xec45d2d56eC37FFaBeb503A27AE21ba806eBE075'
const placeholderAddr = fallbackAddress.substring(2).toLowerCase()

const placeholderReferrer = createReferrerReplacement.substring(2).toLowerCase()

export type PreparedTransaction = TransactionRequest & {
  to: string
  value: bigint | string
}

export function prepareTransaction(
  userAddress: AddressHash,
  txData: ContractTransaction,
  referrer: AddressHash,
): PreparedTransaction {
  const callData = `0x${txData.callData}` as Hex
  const txToCopy: PreparedTransaction = {
    to: txData.to,
    from: userAddress,
    data: callData,
    value: BigInt(txData.ethValue),
  }

  if (txData.gasLimit !== undefined) {
    txToCopy.gas = BigInt(txData.gasLimit)
  }

  // check if our placeholder address is in the tx callData
  if (callData.indexOf(placeholderAddr) !== -1) {
    const currentWalletAddrForReplacement = userAddress
      .substring(2)
      .toLowerCase()
    txToCopy.data = callData.replace(
      placeholderAddr,
      currentWalletAddrForReplacement,
    ) as Hex
  }

  if (
    txToCopy.data !== undefined &&
    txToCopy.data.includes(placeholderReferrer)
  ) {
    const replacementReferrer = isAddressEqual(referrer, userAddress)
      ? zeroAddress
      : referrer

    txToCopy.data = txToCopy.data.replace(
      placeholderReferrer,
      replacementReferrer.substring(2).toLowerCase(),
    ) as Hex
  }

  return txToCopy
}

const erc721TransferEventABI = [
  'event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)',
] as const

const erc1155ABI = [
  'event TransferSingle(address indexed operator, address indexed from, address indexed to, uint256 id, uint256 _value)',
  'event TransferBatch(address indexed operator, address indexed from, address indexed to, uint256[] ids, uint256[] _values)',
] as const

export const parseTokenIds = (tx: TransactionReceipt): string[] => {
  if (tx.logs.length == 0) {
    return []
  }

  const erc721Abi = parseAbi(erc721TransferEventABI)
  const erc1155Abi = parseAbi(erc1155ABI)

  const transfers: {
    from: string
    to: string
    tokenId: string
    contract: string
  }[] = []

  for (const log of tx.logs) {
    try {
      try {
        const parsed = decodeEventLog({
          abi: erc721Abi,
          data: log.data as `0x${string}`,
          topics: [...(log.topics as `0x${string}`[])] as unknown as [
            `0x${string}`,
            ...`0x${string}`[],
          ],
        }) as unknown as {
          eventName: string
          args?: { from: string; to: string; tokenId: bigint }
        }

        if (parsed.args !== undefined) {
          const from = parsed.args.from as string
          const to = parsed.args.to as string
          const tokenId = parsed.args.tokenId
          transfers.push({
            from,
            to,
            tokenId: tokenId.toString(),
            contract: log.address,
          })
        }
      } catch {
        try {
          const parsed = decodeEventLog({
            abi: erc1155Abi,
            data: log.data as `0x${string}`,
            topics: [...(log.topics as `0x${string}`[])] as unknown as [
              `0x${string}`,
              ...`0x${string}`[],
            ],
          })

          if (parsed.eventName === 'TransferSingle') {
            const { from, to, id } = parsed.args as {
              from: string
              to: string
              id: bigint
            }
            transfers.push({
              from,
              to,
              tokenId: id.toString(),
              contract: log.address,
            })
          } else if (parsed.eventName === 'TransferBatch') {
            const {
              from,
              to,
              ids: logTokenIds,
            } = parsed.args as {
              from: string
              to: string
              ids: readonly bigint[]
            }
            logTokenIds.forEach((tokenId) => {
              transfers.push({
                from,
                to,
                tokenId: tokenId.toString(),
                contract: log.address,
              })
            })
          }
        } catch {
          // unable to parse log as a transfer event
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const destination = tx.from
  const tokenIds: string[] = []
  const seenTokenIds = new Set<string>()

  for (const transfer of transfers) {
    if (transfer.to.toLowerCase() === destination.toLowerCase()) {
      if (!seenTokenIds.has(transfer.tokenId)) {
        tokenIds.push(transfer.tokenId)
        seenTokenIds.add(transfer.tokenId)
      }
    }
  }

  return tokenIds
}

import React, { useMemo } from 'react'
import { withErrorBoundary } from '@sentry/react'
import Link from 'next/link'
import {
  MyMintsNFTSample,
  NFTSample,
  Profile,
  getProfileFromArrayIfExists,
} from 'utils/api'
import classNames from 'utils/classnames'
import { useHasMounted } from 'utils/react-helpers'
import { placeholderBackground, textSecondary } from 'utils/theme'
import { formatTimestamp } from 'utils/time'
import { formatTokenAmount } from 'utils/web3/helpers'
import Media from './Media'

export type Props = {
  nft: (NFTSample & { contractName?: never }) | MyMintsNFTSample
  size?: number
  profiles?: Profile[]
  loading?: 'lazy' | 'eager'
}

function NFTView({ nft, size, profiles, loading = 'lazy' }: Props) {
  const hasMounted = useHasMounted()

  const formattedTimestamp = useMemo(
    () => formatTimestamp(new Date(nft.mintTime), 'en_truncated'),
    // We want to rerender when we mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMounted, nft.mintTime],
  )

  const subtitle = useMemo(() => {
    if (nft === undefined) {
      return null
    }

    if (profiles !== undefined) {
      const minter = getProfileFromArrayIfExists(nft.minter, profiles)

      return minter !== undefined ? (
        <>
          Minted by{' '}
          <Link
            href={`/profile/${minter.slug}`}
            className="font-bold hover:underline"
          >
            {minter.name}
          </Link>
        </>
      ) : null
    }

    if (nft.contractName !== undefined) {
      return nft.contractName
    }

    return null
  }, [nft, profiles])

  return (
    <div className="flex flex-col" style={{ width: size }}>
      <div
        style={{ width: size, height: size }}
        className={classNames(
          placeholderBackground,
          'overflow-hidden',
          'rounded-md',
        )}
      >
        <Media nft={nft} width={size} height={size} loading={loading} />
      </div>
      <div className="hidden md:flex flex-col text-xs leading-4 mt-3 overflow-x-hidden text-ellipsis whitespace-nowrap h-12">
        <span className="overflow-x-hidden text-ellipsis whitespace-nowrap normal-case">
          {nft.title}
        </span>
        {subtitle !== undefined ? (
          <span className="overflow-x-hidden text-ellipsis whitespace-nowrap">
            {subtitle}
          </span>
        ) : null}
        <span className="overflow-x-hidden text-ellipsis whitespace-nowrap">
          <span className="uppercase">
            {formatTokenAmount({
              amount: nft.ethPrice,
            })}
          </span>{' '}
          •{' '}
          {hasMounted && (
            <span className={textSecondary}>{formattedTimestamp}</span>
          )}
        </span>
      </div>
    </div>
  )
}

export default React.memo(withErrorBoundary(NFTView, {}))

import React from 'react'
import { CollectionFlag } from 'utils/api'
import classnames from 'utils/classnames'
import HoverCard from '../HoverCard'
import WarningIcon from '../icons/WarningIcon'
import { copyForFlag, warningTitle } from './messages'

type Props = {
  flags: CollectionFlag[]
  className?: string
  buttonClassName?: string
}

function ProjectWarningLabelBadge({
  flags,
  className,
  buttonClassName,
}: Props) {
  return (
    <HoverCard
      trigger={
        <div
          className={classnames('flex items-center pt-1 md:pt-2', className)}
        >
          <WarningIcon className="text-[#FFC700]" />
          <span className="sr-only">Warning</span>
        </div>
      }
      content={
        <div className="flex flex-col gap-4">
          <span className="font-bold text-sm">{warningTitle}</span>
          <ul className="list-disc list-inside">
            {flags.map((flag) => (
              <li key={flag} className="text-sm">
                {copyForFlag[flag]}
              </li>
            ))}
          </ul>
        </div>
      }
      buttonClassName={buttonClassName}
    />
  )
}

export default React.memo(ProjectWarningLabelBadge)

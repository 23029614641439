import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { AddressHash } from 'utils/api'
import { zeroAddressHash } from 'utils/constants'
import { isAddressHash } from 'utils/web3/helpers'

export const useReferrer = () => {
  const { query } = useRouter()
  return useMemo<AddressHash>(() => {
    if (isAddressHash(query.ref)) {
      return query.ref
    }
    return zeroAddressHash
  }, [query.ref])
}

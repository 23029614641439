import { SVGProps } from 'react'

export default function WarningIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10.889 3.667c0-.369.298-.667.667-.667h.888c.369 0 .667.298.667.667v.444H10.89v-.444zM9.778 5.222v-.444c0-.368.298-.667.666-.667h.445v.445a.667.667 0 01-.667.666h-.444zM8.667 6.333V5.89c0-.368.298-.667.666-.667h.445v.445a.667.667 0 01-.667.666h-.444zM7.556 8.556V7c0-.368.298-.667.666-.667h.445V7.89A.667.667 0 018 8.556h-.444zM6.444 10.778V9.222c0-.368.299-.666.667-.666h.445v1.555a.667.667 0 01-.667.667h-.445z"
      ></path>
      <path
        fill="currentColor"
        d="M5.333 13v-1.556c0-.368.299-.666.667-.666h.444v1.555a.667.667 0 01-.666.667h-.445zM4.222 15.222v-1.555c0-.368.299-.667.667-.667h.444v1.556a.667.667 0 01-.666.666h-.445zM3.111 17.444V15.89c0-.368.299-.667.667-.667h.444v1.556a.667.667 0 01-.666.666H3.11z"
      ></path>
      <path
        fill="currentColor"
        d="M3.111 20.778h-.444A.667.667 0 012 20.11v-2c0-.368.298-.667.667-.667h.444v3.334zM20.889 20.778v.444a.667.667 0 01-.667.667H3.778a.667.667 0 01-.667-.667v-.444H20.89z"
      ></path>
      <path
        fill="currentColor"
        d="M20.889 17.444h.444c.369 0 .667.299.667.667v2a.667.667 0 01-.667.667h-.444v-3.334zM19.778 15.222h.444c.368 0 .667.299.667.667v1.555h-.445a.667.667 0 01-.666-.666v-1.556z"
      ></path>
      <path
        fill="currentColor"
        d="M18.667 13h.444c.368 0 .667.299.667.667v1.555h-.445a.667.667 0 01-.666-.666V13zM17.556 10.778H18c.368 0 .667.298.667.666V13h-.445a.667.667 0 01-.666-.667v-1.555zM16.444 8.556h.445c.368 0 .667.298.667.666v1.556h-.445a.667.667 0 01-.667-.667V8.556zM15.333 6.333h.445c.368 0 .666.299.666.667v1.556H16a.667.667 0 01-.667-.667V6.333zM14.222 5.222h.445c.368 0 .666.299.666.667v.444h-.444a.667.667 0 01-.667-.666v-.445z"
      ></path>
      <path
        fill="currentColor"
        d="M14.222 5.222v-.444a.667.667 0 00-.666-.667h-.445v.445c0 .368.299.666.667.666h.444zM9.806 9.833c0-.368.298-.666.666-.666h3.111c.368 0 .667.298.667.666v2.111a.556.556 0 01-.556.556.555.555 0 00-.555.556v2.11a.667.667 0 01-.667.667h-2a.667.667 0 01-.666-.666V9.833zM13.139 17.611a.667.667 0 00-.667-.667h-2a.667.667 0 00-.666.667v.889c0 .368.298.667.666.667h2a.667.667 0 00.667-.667v-.889z"
      ></path>
    </svg>
  )
}

import { CollectionFlag } from 'utils/api'

export const warningTitle =
  'Minting may pose a risk and is not recommended. Proceed with caution!'

export const copyForFlag: Record<CollectionFlag, string> = {
  notEtherscanVerified: 'Contract not verified',
  removedFromOpensea: 'Removed from OpenSea',
  userReported: 'Reported by community',
}

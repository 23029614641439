import React, { useCallback, useMemo, useState } from 'react'
import { Contract, NFTSample, nftThumbnailEndpoint } from 'utils/api'
import classNames from 'utils/classnames'
import { useHasMounted } from 'utils/react-helpers'

const sizeForWindow = (size: number) => {
  if (typeof window === 'undefined') {
    return size
  }

  const dpi = window.devicePixelRatio
  return Math.round(size * dpi)
}

const baseClassName =
  'nfte__media-content aspect-square w-full bg-neutral-200 dark:bg-neutral-800 object-contain'

type BaseProps = {
  width?: number
  height?: number
  className?: string
  loading?: 'lazy' | 'eager'
}

type Props = BaseProps &
  (
    | {
        nft: NFTSample
        tokenId?: never
        collectionContract?: never
      }
    | {
        nft?: never
        tokenId: string
        collectionContract: Contract
      }
  )

function Media({
  nft,
  tokenId,
  collectionContract,
  width,
  height,
  className,
  loading = 'lazy',
}: Props) {
  const [shouldUseFallback, shouldUseFallbackSet] = useState(false)

  const handleDefaultImageError = useCallback(() => {
    if (!shouldUseFallback) {
      shouldUseFallbackSet(true)
    }
  }, [shouldUseFallback])

  const hasMounted = useHasMounted()

  const src = useMemo(() => {
    if (shouldUseFallback) {
      return '/media-not-available.png'
    }

    const { contract, tid } =
      nft !== undefined
        ? { tid: nft.tokenID, contract: nft.contractAddress }
        : { tid: tokenId, contract: collectionContract }

    return nftThumbnailEndpoint({
      contract: contract,
      tokenId: tid,
      width: width !== undefined ? sizeForWindow(width) : undefined,
      height: height !== undefined ? sizeForWindow(height) : undefined,
    })

    // we want to re-memo this when we are mounted (have the screen DPI)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldUseFallback,
    nft,
    width,
    height,
    tokenId,
    collectionContract,
    hasMounted,
  ])

  if (!hasMounted) {
    return (
      <div
        className={classNames(baseClassName, className)}
        style={{ width, height }}
      />
    )
  }

  return (
    <img
      onError={handleDefaultImageError}
      className={classNames(baseClassName, className)}
      loading={loading}
      src={src}
      width={width}
      height={height}
      alt={nft ? `NFT media for ${nft.title}` : `NFT media`}
    />
  )
}

export default React.memo(Media)

import { useCallback } from 'react'
import { useSWRConfig } from 'swr'
import { InvalidInputRpcError } from 'viem'
import {
  Contract,
  PendingTransactionsResponse,
  contractPendingTransactionsEndpoint,
} from 'utils/api'
import { createInsufficientFundsToast, createToast } from 'utils/toasts'
import {
  isInsufficientFundsGasEstimation,
  isUserRejectedRequestError,
} from 'utils/web3/helpers'

export const handleTransactionFailureError = (
  error: unknown,
  message = 'Unable to mint',
) => {
  console.error('transaction error', error)

  if (isUserRejectedRequestError(error)) {
    return
  }

  if (error instanceof InvalidInputRpcError) {
    return
  }

  if (isInsufficientFundsGasEstimation(error)) {
    createInsufficientFundsToast()
    return
  }

  console.error('Unknown error type', typeof error, error instanceof Error)
  createToast(message)
}

export const useUpdatePendingTxCache = (collectionContract: Contract) => {
  const { mutate } = useSWRConfig()
  return useCallback(() => {
    mutate<PendingTransactionsResponse>(
      contractPendingTransactionsEndpoint({
        contract: collectionContract,
      }),
      async (existing) => {
        if (existing === undefined) {
          return {
            total: 1,
            wallets: 1,
          }
        }

        return {
          ...existing,
          total: existing.total + 1,
          wallets: existing.wallets + 1,
        }
      },
      { revalidate: false },
    )
  }, [collectionContract, mutate])
}

import React from 'react'
import { lazy } from 'utils/lazy'

const HoverCard = lazy({
  loader: () => import('./HoverCard'),
  Fallback: ({ props }) => (
    <button
      type="button"
      aria-haspopup="dialog"
      aria-expanded="false"
      data-state="closed"
      className={props.buttonClassName}
    >
      {props.trigger}
    </button>
  ),
})

export default HoverCard
